import Main from '@/app/components/Home'
import Head from 'next/head'

export default function HomePage() {
  return (
    <>
      <Head>
        <title>Azul Agro - Máquinas e Equipamentos Agrícolas de Alta Performance</title>
        <meta name="description" content="Descubra a Azul Agro, líder em máquinas e equipamentos agrícolas. Oferecemos soluções de alta performance para otimizar suas operações no campo. Conheça nossos produtos e impulsione sua produtividade!" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/azul-agro/desktop/home/introSection.webp"} media="(min-width: 1140px)" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/azul-agro/tablet/home/introSection.webp"} media="(min-width: 540px) and (max-width: 1139px)" />
        <link rel="preload" as="image" href={"https://gabcomercio.azureedge.net/azul-agro/mobile/home/introSection.webp"} media="(max-width: 539px)" />
      </Head>
      <Main />
    </>
  )
}
