import { useMediaQuery } from "react-responsive"
import React, { useEffect, useState } from "react"
import HomeDesktop from "./HomeDesktop"
import HomeMobile from "./HomeMobile"

const Main: React.FC = () => {
    const isDesktop = useMediaQuery({ minWidth: 1140 })
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    // Renderiza nulo até que o componente esteja montado para evitar problemas de hidratação
    if (!isMounted) {
        return null
    }

    return (
        <>
            { isDesktop ? <HomeDesktop /> : <HomeMobile /> }
        </>
    )
}

export default React.memo(Main)
